<template>
    <div>
        <app-layout>
	        <template v-slot:header>
		        <Header :title="$t('receipt_search')"
		                :isFilter="false">
		        </Header>
	        </template>
	        <template v-slot:header-mobile>
		        <HeaderMobile :title="$t('receipt_search')"
		                      :isFilter="false">
		        </HeaderMobile>
	        </template>
            <datatable-filter
                v-show="datatable.filterStatus"
                @filter="$refs.datatable.getRows()"
                @filterClear="filterClear"
                :exportExcel="true" @exportExcel="$refs.datatable.excel()"
            >
                <b-row>
                    <!-- FILTER -- STUDENT NUMBER -->
                    <b-col sm="12" md="6" lg="3">
                        <b-form-group :label="$t('student_number')">
                            <b-form-input
                                type="number"
                                min="0"
                                v-check-min-max-value
                                step="1"
                                v-model="datatable.queryParams.filter.number"
                            >
                            </b-form-input>
                        </b-form-group>
                    </b-col>

                    <!-- FILTER -- NATIONAL ID -->
                    <b-col sm="12" md="6" lg="3">
                        <b-form-group :label="$t('national_id')">
                            <b-form-input
                                type="number"
                                min="0"
                                v-check-min-max-value
                                step="1"
                                v-model="
                                    datatable.queryParams.filter.national_id
                                "
                            >
                            </b-form-input>
                        </b-form-group>
                    </b-col>

                    <!-- FILTER -- RECEIPT NUMBER -->
                    <b-col sm="12" md="6" lg="3">
                        <b-form-group :label="$t('receipt_number')">
                            <b-form-input
                                type="text"
                                v-model="
                                    datatable.queryParams.filter.receipt_number
                                "
                            >
                            </b-form-input>
                        </b-form-group>
                    </b-col>

                    <!-- FILTER -- TRANSACTION DATE -->
                    <b-col sm="12" md="6" lg="3">
                        <b-form-group :label="$t('transaction_date')">
                            <b-input-group>
                                <select-datet
                                    id="example-input"
                                    v-model="
                                        datatable.queryParams.filter
                                            .transaction_date
                                    "
                                    placeholder="MM/DD/YYYY"
                                    autocomplete="off"
                                ></select-datet>
                            </b-input-group>
                        </b-form-group>
                    </b-col>

                    <!-- FILTER -- PAYMENT PERIOD -->
                    <b-col sm="12" md="6" lg="3">
                        <b-form-group :label="$t('period')">
                            <payment-periods-selectbox
                                v-model="datatable.queryParams.filter.period_id"
                            ></payment-periods-selectbox>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable
                ref="datatable"
                :isLoading.sync="datatable.isLoading"
                :columns="datatable.columns"
                :rows="datatable.rows"
                :total="datatable.total"
                :queryParams="datatable.queryParams"
                v-show="datatable.showTable"
                :data-function="callService"
            >
            </datatable>
        </app-layout>
    </div>
</template>

<script>
import "../styles/index.css";
import qs from "qs";
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import PaymentPeriodsSelectbox from "@/components/interactive-fields/PaymentPeriodsSelectbox";
import PaymentService from "@/services/PaymentService";

export default {
    components: {
        AppLayout,
        Header,
	    HeaderMobile,
        DatatableFilter,
        Datatable,
        PaymentPeriodsSelectbox,
    },
    metaInfo() {
        return {
            title: this.$t("receipt_search"),
        };
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass:
                            "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t("print"),
                                class: "ri-printer-line align-middle top-minus-1 mr-3 text-muted",
                                callback: (row) => {
                                    this.print(row.receipt_number)
                                },
                            },
                        ],
                    },
                    {
                        label: this.toUpperCase("student_number"),
                        field: "number",
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("national_id"),
                        field: "national_id",
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("name"),
                        field: 'name',
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("surname"),
                        field: 'surname',
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("receipt_number"),
                        field: "receipt_number",
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("amount"),
                        field: "amount",
                        formatFn: this.formatNumber
                    },
                    {
                        label: this.toUpperCase("transaction_date"),
                        field: "transaction_date",
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("period"),
                        field: "period_code",
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("created_by"),
                        field: "created_by",
                        hidden: false,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "-transaction_date",
                    page: 1,
                    limit: 20,
                },
            },
        };
    },
    created() {
        this.filterSet();
    },
    methods: {
        callService(config) {
            return PaymentService.collectionReceiptSearch(config)
        },
        print(receiptNumber) {
            PaymentService.getReceipt(receiptNumber)
                .then(response => {
                    const blob = new Blob([response.data], {type: response.headers['content-type']})
                    const objectUrl = window.URL.createObjectURL(blob)
                    window.open(objectUrl)
                });
        },

        showErrors(e) {
            if (e.status == "406") {
                this.$toast.error(this.$t("api." + e.data.message));
            } else if (e.status == "500") {
                console.error("500: Internal Server Error");
            }
        },

        filterSet() {
            this.datatable.queryParams.filter = {
                number: null,
                national_id: null,
                receipt_number: null,
                transaction_date: null,
                period_id: null,
            };
        },
        filterClear() {
            this.filterSet();
            this.getRows();
        },
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        changeColumn(key) {
            this.datatable.columns[key].hidden =
                this.datatable.columns[key].hidden == true ? false : true;
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams,
                },
                paramsSerializer: (params) =>
                    qs.stringify(params, { encode: false }),
            };

            return PaymentService.collectionReceiptSearch(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total;
                })
                .catch((e) => this.showErrors(e))
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
    },
};
</script>
